import { css, jsx } from '@emotion/core';
import { Pagination, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Container from '@/src/shared/components/Container';
import Head from '@/src/shared/components/Head';
import Hero from '@/src/shared/components/Hero';
import Layout from '@/src/shared/components/Layout';
import ResultCard from '@/src/shared/components/ResultCard';
import Section from '@/src/shared/components/Section';
import WebLogImage from '@/src/shared/components/WebLogImage';
import { colors, space } from '@/styles';
import { getPath } from '@/utils/utils';

const style = {
  resultsContainer: css`
    background-color: ${colors.accentGrayUltralight};
    @media print {
      background-color: ${colors.white};
      margin-top: 0px;
    }
  `,
  noHero: noHero =>
    noHero
      ? css`
          padding-top: 0 !important;
          margin-top: -30px;
        `
      : css``,
  noPrint: css`
    ${space.m.b20};
    @media print {
      display: none;
    }
  `,
};

const ListPage = props => {
  const {
    results,
    pageSize,
    title,
    heroContent,
    renderResultSubtitle,
    truncateResultSummary,
    pagination,
    current,
    handlePaginationChange,
    renderBeforeList,
    loading,
    overrideLast = false,
    noHero,
    noResultsDisplay,
    statecode,
  } = props;

  const filteredResults = (results || []).slice((current - 1) * pageSize, current * pageSize);

  const hasResults =
    results &&
    ((results.length && results.length > 1) ||
      (results.length === 1 && results[0].SitePageId !== 0));

  const noResults =
    noResultsDisplay !== undefined ? noResultsDisplay : <p>No recent entries found.</p>;

  return (
    <Layout>
      {title && title.length ? <Head title={title} /> : null}

      {!noHero && (
        <Hero title={title} overrideLast={overrideLast}>
          {heroContent}
        </Hero>
      )}
      <Section css={[style.resultsContainer, style.noHero(noHero)]}>
        <Container size="sm">
          {renderBeforeList()}
          {loading ? (
            <>
              {pagination && <Skeleton title />}
              <Skeleton avatar />
              <Skeleton avatar />
              <Skeleton avatar />
            </>
          ) : (
            <>
              {pagination && hasResults ? (
                <Pagination
                  onChange={handlePaginationChange}
                  total={results.length}
                  pageSize={pageSize}
                  current={current}
                  css={style.noPrint}
                />
              ) : null}

              {hasResults
                ? filteredResults.map(result => {
                    const [as, href] = getPath(result.Cat1Id, result.SitePageId);
                    return (
                      <ResultCard
                        key={result.SitePageId}
                        href={href}
                        as={as}
                        title={result.PageTitle}
                        subtitle={renderResultSubtitle(result)}
                        summary={result.ExecutiveSummary}
                        truncate={truncateResultSummary}
                        summaryLimit={truncateResultSummary ? 250 : 10000}
                        iconVisible={false}
                      />
                    );
                  })
                : noResults}
              {pagination && hasResults ? (
                <Pagination
                  onChange={handlePaginationChange}
                  total={results.length}
                  pageSize={pageSize}
                  current={current}
                  css={style.noPrint}
                />
              ) : null}
            </>
          )}
        </Container>
      </Section>
      <WebLogImage browserPath title={title} sitePageId={statecode} />
    </Layout>
  );
};

ListPage.propTypes = {
  heroContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageSize: PropTypes.number,
  renderResultSubtitle: PropTypes.func,
  truncateResultSummary: PropTypes.bool,
  pagination: PropTypes.bool,
  current: PropTypes.number,
  handlePaginationChange: PropTypes.func,
  renderBeforeList: PropTypes.func,
  loading: PropTypes.bool,
};

ListPage.defaultProps = {
  heroContent: null,
  pageSize: 1000,
  renderResultSubtitle: result => new Date(result.PublishDate).toLocaleDateString(),
  truncateResultSummary: false,
  pagination: false,
  current: 1,
  handlePaginationChange: () => {},
  renderBeforeList: () => null,
  loading: false,
};

export default ListPage;
